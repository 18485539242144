import React from "react";
import {
	Box,
	Button,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";

const EmptyContent = (props) => {
	const {
		item,
		onStart
	} = props;
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Typography
				className={classes.message}
				dangerouslySetInnerHTML={{
					__html: item?.description || item?.short_description || ""
				}}
			/>
			<Button
				variant="contained"
				className={classes.button}
				onClick={onStart}
			>Начать тренировку</Button>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		height: "100%",
		overflow: "auto"
	},
	button: {
		marginTop: 24
	},
	message: {
		fontSize: 21,
		lineHeight: 1.2,
		color: "#254164",
		"& strong": {
			fontWeight: "500",
		}
	}
}));

export default EmptyContent
