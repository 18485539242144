import React from "react";
import {
	Box,
	CircularProgress
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";

const Loading = () => {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<div className={classes.image}>
				<img
					src={require('../../../../../assets/image/logo/logo-people.png')}
				/>
				<CircularProgress
					className={classes.circularProgress}
					thickness={0.2}
				/>
			</div>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "center",
		width: "100%",
		height: "100%"
	},
	image: {
		maxWidth: 320,
		maxHeight: 320,
		position: "relative",

		"& img": {
			width: "100%",
			height: "100%",
			objectFit: "contain",
		}
	},
	circularProgress: {
		position: "absolute",
		top: "-8px", left: "-8px",
		width: "calc(100% + 16px)!important",
		height: "calc(100% + 16px)!important",

		// "& circle": {
		// 	strokeWidth: "0.5px",
		// }
	}
}));

export default Loading
