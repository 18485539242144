import React from "react";
import {
	Box,
	Button,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {

} from "./"

const Header = (props) => {
	const {
		title,
		isStop,
		onStop
	} = props;
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Typography className={classes.title}>{ title }</Typography>

			{Boolean(isStop) && (
				<Button className={classes.button} variant="contained" onClick={onStop}>
					Закончить тренировку
				</Button>
			)}
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "12px 24px 6px",
		boxSizing: "border-box",
		background: "white",
		boxShadow: "0px 1px 2.5px 0px #0000001A",
		borderRadius: 16,
		marginBottom: 10
	},
	title: {
		fontSize: 20,
		lineHeight: 1,
		color: "#2C4058",
		fontWeight: "500"
	},
	button: {
		padding: "4px 6px 2px",
		borderRadius: "4px",
		background: "#FF6D7B!important",
		borderColor: "#FF6D7B!important",

		fontSize: 12,
		lineHeight: 1
	}
}))

export default Header
