import React from 'react';
import {
	Box,
	Button,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	VisibleContent,
	ButtonPlaySound
} from "../../../../../components";
import clsx from "clsx";
import {playMp3FromBase64} from "../../../../../helpers/voice";

const MessagesContent = ({ list }) => {
	const classes = useStyles();

	return (
		<div className={clsx([classes.root, 'scrollbar-body'])}>
			{list.map((item) => {
				return (
					<>
						<VisibleContent key={`ChatAssistant-${ item?.assistant_message }-${ item?.assistant_id }`} visible={Boolean(item?.assistant_message)}>
							<ChatAssistant item={item}/>
						</VisibleContent>
						<VisibleContent visible={Boolean(item?.user_message)}>
							<ChatUser item={item}/>
						</VisibleContent>
					</>
				)
			})}
		</div>
	)
};
const ChatUser = React.memo(({ item }) => {
	const classes = useStyles();
	return (
		<Box className={classes.chatUser}>
			<Typography className={classes.chatUserMessage} dangerouslySetInnerHTML={{__html: item?.user_message}}/>
		</Box>
	)
});
const ChatAssistant = React.memo(({ item }) => {
	const classes = useStyles();
	return (
		<Box className={classes.chatAssistant}>
			<Typography className={classes.chatAssistantMessage} dangerouslySetInnerHTML={{__html: item?.assistant_message}}/>
			<VisibleContent visible={Boolean(item?.audio)}>
				<ButtonPlaySound
					sound={item?.audio}
					autoplay={Boolean(item?.isAutoplay)}
				/>
			</VisibleContent>
		</Box>
	)
});

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column-reverse",
		gap: "8px",
		height: "100%",
		overflow: "auto",
		padding: "32px 0",
		marginTop: "-20px",
		marginLeft: "-4px",
		paddingLeft: "4px",
		paddingRight: "12px",
		marginRight: "-12px",
		marginBottom: "60px",
		boxSizing: "border-box",

		"& > div:last-child": {
			marginTop: "auto!important"
		}
	},

	chatAssistant: {
		maxWidth: "80%",
		background: "white",
		padding: "12px 16px",
		borderRadius: "12px 12px 12px 0",
		boxShadow: "0 1px 8px -4px #254164b8"
	},
	chatAssistantMessage: {
		fontSize: 16,
		lineHeight: 1.2,
		color: "#254164",
	},

	chatUser: {
		marginLeft: "auto",
		maxWidth: "80%",
		background: "#f7faff",
		padding: "12px 16px",
		borderRadius: "12px 12px 0 12px",
		boxShadow: "0 1px 8px -4px #254164b8"
	},
	chatUserMessage: {
		fontSize: 16,
		lineHeight: 1.2,
		color: "#254164",
	}
}));

export default MessagesContent
