import {combineReducers} from 'redux';

import profile from "../states/profile";
import trainers from "../states/trainers";

const rootReducer = combineReducers({
	profile,
	trainers
});

export default rootReducer
