import React from "react";
import {
	Box,
	Portal,
	Backdrop,
	Typography,
	CircularProgress,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";

const BackdropLoading = ({ open, message }) => {
	const classes = useStyles();
	if (!open) {
		return null
	}
	return (
		<Portal>
			<Backdrop open={true}>
				<Box className={classes.root}>
					<Box className={classes.image}>
						<img src={require('../../assets/image/logo/logo-people.png')}/>
						<CircularProgress sx={{ color: "#005FD6" }}/>
					</Box>

					{Boolean(message) && (
						<Typography
							className={classes.message}
							dangerouslySetInnerHTML={{
								__html: message
							}}
						/>
					)}
				</Box>
			</Backdrop>
		</Portal>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		// background: "#FFFFFF",
		borderRadius: 16,
		border: "1ox solid rgba(0,0,0,0.1)",
		boxShadow: "0px 1px 2.5px 0px #0000001A",
		padding: 40,
		paddingTop: 0,
		width: "100%",
		maxWidth: 500
	},
	image: {
		display: "flex",
		width: "100%",
		maxWidth: 320,
		position: "relative",
		overflow: "hidden",
		background: "rgba(255,255,255, 1)",
		borderRadius: "100%",
		marginBottom: 20,
		boxShadow: "0 0 10px white",

		"& img": {
			width: "100%",
			height: "100%",
			objectFit: "contain",
			objectPosition: "center",
			position: "absolute",
			top: 0, left: 0,
		},
		"&:before": {
			content: "''",
			width: "100%",
			float: "left",
			paddingTop: "100%"
		},
		"& .MuiCircularProgress-root": {
			width: "calc(100% + 50px)!important",
			height: "calc(100% + 50px)!important",
			borderRadius: "100%",
			position: "absolute",
			top: "-25px", left: "-25px"
		}
	},
	message: {
		fontSize: 20,
		fontWeight: "500",
		lineHeight: 1.4,
		textAlign: "center",
		color: "white",
	}
}));

export default BackdropLoading
