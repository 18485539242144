import agent from "../agent/agent";

const GET_TRAINERS = "trainers/GET_TRAINERS";

const initialState = {
  trainers: [],
};

export function getTrainers () {
  return async function (dispatch) {
    const res = await agent.get('/api/v1/trainers')
      .then((res) => res?.data)
      .catch(() => [])

    dispatch({
      type: GET_TRAINERS,
      data: res
    });
  }
}

export default function GlobalState(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TRAINERS: {
      return {
        ...state,
        trainers: action.data
      }
    }

    default:
      return state;
  }
}
