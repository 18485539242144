import React from "react";
import {
	Box,
	Grid,
	Button,
	Tooltip,
	TextField,
	IconButton,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	AttachFile as AttachFileIcon,
	SettingsVoice as SettingsVoiceIcon,
	KeyboardVoice as KeyboardVoiceIcon,
} from "@mui/icons-material";
import {
	VisibleContent
} from "../../../../../components";
import {
	useSpeechRecognition,
} from 'react-speech-recognition'
import {
	useAudioRecorder
} from 'react-audio-voice-recorder';
import {convertorWebmToMp3} from "../../../../../helpers/voice";

const FooterAction = (props) => {
	const {
		chatType,
		disabledVoice,
		disabledSelect,

		onSelect,
		onSendNewQuestion,
		onSendVoiceToChat,
	} = props;
	const classes = useStyles();

	const refVoiceItem = React.createRef();
	const [isRecording, setRecording] = React.useState(false);
	const handleChangeRecording = (value) => {
		setRecording(value);
	}

	const [question, setQuestion] = React.useState("");
	const handleChangeQuestion = ({target}) => {
		const {value} = target;
		setQuestion(value);
	};
	const handleSendQuestion = () => {
		if (isRecording) {
			refVoiceItem.current.submit();
			return
		}
		if (question.length <= 0) {
			return null
		}

		onSendNewQuestion(question);
		setQuestion("");
	}

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			handleSendQuestion();
		}
	}

	return (
		<Box className={classes.root}>
			<Grid container spacing={2}>
				<VisibleContent visible={!disabledSelect}>
					<Grid item>
						<Tooltip title="Прикрепить файлы" arrow>
							<Button
								color="primary"
								sx={{padding: "7px 20px"}}
								onClick={onSelect}
							>
								<AttachFileIcon sx={{fontSize: 24}}/>
								Прикрепить файл
							</Button>
						</Tooltip>
					</Grid>
				</VisibleContent>
				<VisibleContent visible={disabledSelect}>
					<VisibleContent visible={!disabledVoice}>
						<VoiceItem
							ref={refVoiceItem}
							onSend={onSendVoiceToChat}
							onChangeRecording={handleChangeRecording}
						/>
					</VisibleContent>
					<Grid item>
						<Tooltip title="Прикрепить файлы" arrow>
							<IconButton
								color="primary"
								onClick={onSelect}
							>
								<AttachFileIcon sx={{fontSize: 24}}/>
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item sx={{flex: "auto"}}>
						<TextField
							value={isRecording ? '' : question}
							fullWidth
							disabled={isRecording}
							variant="outlined"
							placeholder="Введите ваш вопрос"
							onKeyDown={handleKeyDown}
							onChange={handleChangeQuestion}
						/>
					</Grid>
					<Grid item>
						<Button
							size="small"
							variant="contained"
							disabled={!question && !isRecording}
							className={classes.buttonSubmit}
							onClick={handleSendQuestion}
						>Отправить</Button>
					</Grid>
				</VisibleContent>
			</Grid>
		</Box>
	)
}
const VoiceItem = React.forwardRef((props, ref) => {
	const {
		onSend,
		onChangeRecording
	} = props;
	const {
		isMicrophoneAvailable,
		browserSupportsSpeechRecognition,
	} = useSpeechRecognition();
	const {
		isRecording,
		startRecording,
		stopRecording,
		recordingBlob,
		mediaRecorder
	} = useAudioRecorder({
		noiseSuppression: true
	});
	React.useEffect(() => {
		if (!recordingBlob) return;
		(async () => {
			await handleSendBlob(recordingBlob);
		})();
	}, [recordingBlob]);
	React.useEffect(() => {
		onChangeRecording(isRecording);
	}, [isRecording]);

	const _tooltip = () => {
		if (!isMicrophoneAvailable) {
			return "Микрофон недоступен"
		}
		if (!browserSupportsSpeechRecognition) {
			return "Браузер не поддерживает микрофон"
		}
		if (isRecording) {
			return "Закончить запись"
		}
		return "Начать запись"
	};

	const handleStart = async () => {
		if (isRecording) {
			await handleStop();
			return
		}
		await startRecording();
	};
	const handleStop = async () => {
		await stopRecording();
	};
	const handleSendBlob = async (blob) => {
		const _blob = await convertorWebmToMp3(blob);
		await onSend(_blob);
	}

	React.useImperativeHandle(ref, () => ({
		submit: handleStop
	}));

	const Icon = Boolean(isRecording) ? SettingsVoiceIcon : KeyboardVoiceIcon;
	return (
		<Grid item>
			<Tooltip title={_tooltip()} arrow>
				<div>
					<IconButton
						color="primary"
						disabled={!isMicrophoneAvailable || !browserSupportsSpeechRecognition}
						onClick={handleStart}
					>
						<Icon sx={{fontSize: 24}}/>
					</IconButton>
				</div>
			</Tooltip>
		</Grid>
	)
})

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "10px 24px",
		boxSizing: "border-box",
		background: "white",
		boxShadow: "0px 1px 2.5px 0px #0000001A",
		marginTop: 10,
		borderRadius: 16
	},
	buttonSubmit: {
		height: 40,
		fontSize: 14,
		lineHeight: 1,
		borderRadius: 10,
		padding: "8px 20px"
	}
}));

export default FooterAction
