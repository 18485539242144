import React, {Component} from "react";
import {
	Grid
} from "@mui/material";
import {
	ListWorkouts
} from "../../../components";
import {
	NoResult
} from "./components";

class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {

		};
	}

	componentDidMount = () => {
	}

	render() {
		return (
			<Grid container spacing={2}>
				<Grid item xs={3}>
					<ListWorkouts/>
				</Grid>
				<Grid item xs={9} sx={{ display: "flex", height: "calc(100vh - 92px)" }}>
					<NoResult
						message={'Рады приветствовать Вас в нашем тренировочном центре AISynergy.<br/>Для начала тренировки выберите интересующию вас тренировку и нажмите кнопку "Начать"'}
					/>
				</Grid>
			</Grid>
		);
	}
}

export default Home
