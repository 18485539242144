import React from "react";
import {
	Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	ExpandLess as ExpandLessIcon,
	ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
	useSelector
} from "react-redux";
import {Link} from "react-router-dom";
import clsx from "clsx";

const ListWorkouts = () => {
	const classes = useStyles();
	const trainers = useSelector((state) => state.trainers.trainers || []);

	return (
		<Box className={classes.root}>
			<ListSubHeader component="div"/>
			<List className={clsx([classes.list, 'scrollbar-body'])}>
				{trainers.map((item) => (
					<ListItemCustom
						key={`ListItemCustom-${ item.group_id }`}
						item={item}
					/>
				))}
			</List>
		</Box>
	)
};
const ListSubHeader = React.memo(() => {
	const classes = useStyles();
	return (
		<ListSubheader className={classes.listSubHeader}>
			Список тренажеров
		</ListSubheader>
	)
});
const ListItemCustom = React.memo(({ item }) => {
	const classes = useStyles();
	const assistants = (item?.assistants || []);
	const isDisabled = Boolean((assistants || []).length <= 0);

	const [isOpen, setOpen] = React.useState(!isDisabled);
	const IconButtonCollapse = Boolean(isOpen) ? ExpandLessIcon : ExpandMoreIcon;
	const handleChangeOpen = () => {
		setOpen(!isOpen)
	}

	return (
		<>
			<ListItemButton
				className={classes.listItemButton}
				disabled={isDisabled}
				onClick={handleChangeOpen}
			>
				<ListItemText primary={item.name}/>
				<IconButtonCollapse/>
			</ListItemButton>
			<Collapse in={isOpen} timeout="auto" unmountOnExit>
				<List disablePadding>
					{assistants.map((assistant) => (
						<ListItemButton
							key={`assistant-${ assistant.assistant_id }`}
							component={Link}
							to={`/assistant/${ assistant.assistant_id }`}
						>
							<ListItemText
								primary={assistant?.name}
								secondary={assistant?.short_description}
								className={classes.collapseListItemButton}
							/>
						</ListItemButton>
					))}
				</List>
			</Collapse>
		</>
	)
});

const useStyles = makeStyles(() => ({
	root: {
		boxShadow: "0px 1px 2.5px 0px #0000001A",
		borderRadius: 16,
		background: "white",
		border: "1px solid #0000001A",
		boxSizing: "border-box",
		overflow: "hidden",
		position: "relative",
		paddingTop: 20,
	},
	list: {
		overflow: "auto",
		maxHeight: "calc(100vh - 170px)",
	},
	listSubHeader: {
		fontSize: "20px",
		lineHeight: "24px",
		color: "#2E8BFF",
	},
	listItemButton: {
		"& .MuiTypography-root": {
			fontSize: 16,
			color: "#254164"
		},
		"& .MuiListItemText-root": {
			margin: 0
		}
	},
	collapseListItemButton: {
		paddingLeft: 16,
		"& .MuiTypography-body1": {
			lineHeight: 1.1
		},
		"& .MuiTypography-body2": {
			marginTop: 6
		},
		"& .MuiTypography-root": {
			fontSize: 14,
			color: "#254164"
		},
		"& .MuiListItemText-root": {
			margin: 0
		},
		"& .MuiListItemText-secondary": {
			fontSize: 12,
			lineHeight: "14px",
			opacity: 0.5
		}
	}
}));

export default ListWorkouts
