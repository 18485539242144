import React from "react";
import {
	Button
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {closeCurrentPlayAudio, playMp3FromBase64} from "../../helpers/voice";

const ButtonPlaySound = ({ sound, autoplay }) => {
	const classes = useStyles();

	const [isPlay, setPlay] = React.useState(false);
	const handlePlay = async () => {
		await setPlay(true);
		await playMp3FromBase64(sound, handleCancel);
	};
	const handleCancel = async () => {
		await closeCurrentPlayAudio();
		setPlay(false);
	};
	const handleClick = async () => {
		if (!isPlay) {
			await handlePlay();
		} else {
			await handleCancel();
		}
	};

	React.useEffect(() => {
		(async () => {
			if (autoplay) {
				await handlePlay()
			}
		})();
	}, []);

	return (
		<Button className={classes.root} variant="contained" onClick={handleClick}>
			{isPlay ? 'Стоп' : 'Проиграть'}
		</Button>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		marginTop: "8px",
		padding: "4px 8px 2px",
		boxSizing: "border-box",
		borderRadius: 4,

		fontSize: 14,
		lineHeight: 1.2
	}
}));

export default ButtonPlaySound
