import React from "react";
import {
	Box,
	Grid,
	Button,
	IconButton,
	Typography,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Person as PersonIcon,
	PlayArrow as PlayArrowIcon
} from "@mui/icons-material";
import clsx from "clsx";
import {playMp3FromBase64} from "../../../../../helpers/voice";

const ChatHistory = (props) => {
	const {
		items,
		audioFiles
	} = props;
	console.log('audioFiles: ',audioFiles);
	const classes = useStyles();

	const _isShowFiles = (item, index) => {
		const currentFiles = [...(item.file_names || [])].join('___');
		const prevFiles = [...(items?.[index - 1]?.file_names || [])].join('___');

		return Boolean(currentFiles !== prevFiles)
	}

	return (
		<Box className={`${classes.root} scrollbar-body`}>
			<Box className={classes.grid}>
				{items.map((item, index) => (
					<ChatItem
						key={`ChatItem-${ item.id }`}
						item={item}
						audio={audioFiles[item?.user_message]}
						isShowFiles={_isShowFiles(item, index)}
					/>
				))}
			</Box>
		</Box>
	)
};
const ChatItem = ({ item, audio, isShowFiles }) => {
	const classes = useStyles();
	const isFiles = Boolean((item?.file_names || []).length > 0);
	const regex = /\*\*(.*?)\*\*/g;

	const handleSound = async () => {
		await playMp3FromBase64(audio)
	};

	return (
		<>
			{Boolean(isFiles && isShowFiles) && (
				<FilesSelected files={item?.file_names}/>
			)}

			{!Boolean(item?.isOnlyFiles) && (
				<Box className={classes.chatItem}>
					<Box className={clsx([classes.chatItemRow, classes.question])}>
						<Typography className={clsx([classes.chatItemRowIcon, classes.questionIcon])}>
							<PersonIcon sx={{ color: "white" }}/>
						</Typography>
						<Typography
							className={clsx([classes.chatItemRowText, classes.questionText])}
							dangerouslySetInnerHTML={{
								__html: item.user_message
							}}
						/>
					</Box>
					<Box className={clsx([classes.chatItemRow, classes.answer])}>
						<div>
							<Typography
								className={classes.answerText}
								dangerouslySetInnerHTML={{
									__html: String(item.assistant_message || '')
										.replaceAll('\n', '<br/>')
										.replace(regex, "<strong>$1</strong>")
								}}
							/>
							{Boolean(audio) && (
								<Button
									size="small"
									color="primary"
									variant="contained"
									sx={{padding: "2px 8px", paddingLeft: "12px", display: "flex", gap: "4px", marginTop: "6px", fontSize: 14}}
								  onClick={handleSound}
								>
									Проиграть
									<PlayArrowIcon sx={{fontSize: 20}}/>
								</Button>
							)}
						</div>
					</Box>
				</Box>
			)}
		</>
	)
};
const FilesSelected = ({ files }) => {
	const classes = useStyles();
	return (
		<>
			<Box mt={4}/>
			<Grid container spacing={2} justifyContent="flex-end">
				{files.map((file) => (
					<Grid item>
						<Typography className={classes.fileSelected}>
							{ file }
						</Typography>
					</Grid>
				))}
			</Grid>
			<Box mt={2}/>
		</>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column-reverse",
		flex: 1,
		overflow: "auto",
		padding: "20px 24px",
		boxSizing: "border-box",
		maxHeight: "calc(100vh - 242px)",

		width: "100%",
		borderRadius: "16px",
		border: "0.5px solid rgba(0, 0, 0, 0.10)",
		boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",

		backgroundImage: "radial-gradient(rgb(0 0 0 / 1%) 20%, transparent 20%), radial-gradient(#fafafa 20%, transparent 20%)",
		backgroundColor: "#ffffff",
		backgroundPosition: "0 0, 150px 150px",
		backgroundSize: "5px 5px",
	},
	grid: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
	},

	chatItem: {},
	chatItemRow: {
		display: "flex",
		gap: 15
	},
	chatItemRowIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 42,
		height: 42,
		borderRadius: "100%",
	},
	questionIcon: {
		background: "#0F7AFF"
	},
	questionText: {
		paddingTop: 10,
		fontSize: 20,
		lineHeight: 1.2,
		color: "#254164",
		fontWeight: "500"
	},
	answer: {
		marginLeft: 21,
		paddingTop: 10,
		paddingLeft: 36,
		paddingBottom: 10,
		borderLeft: "1px solid #0F7AFF"
	},
	answerText: {
		fontSize: 15,
		lineHeight: 1.4,
		color: "#71869F",

		"& strong": {
			fontWeight: "500",
		}
	},

	fileSelected: {
		border: "1px solid #0000001A",
		borderRadius: 80,
		padding: "10px 20px",
		boxSizing: "border-box",
		background: "linear-gradient(90deg, #126DDE 0%, #9E22FF 100%)",

		fontSize: 15,
		lineHeight: 1.2,
		color: "white",
		fontWeight: "600"
	}
}));

export default ChatHistory
