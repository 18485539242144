import React from 'react';
import {
  compose
} from 'recompose';
import {
  connect
} from 'react-redux';
import {
  LoadingContent
} from "./components";
import {
	withRouter
} from "react-router-dom";
import {
	authGetMe
} from "../states/profile";
import Auth from "./Auth";
import Cabinet from "./Cabinet";
import {getTrainers} from "../states/trainers";

class Router extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true
		};
	}

  componentDidMount = async () => {
		await this.props.authGetMe();
		await this.setState({ isLoading: false });
  }
	initUser = async () => {}

  render () {
    const {
      isLoading
    } = this.state;
    const {
      profile
    } = this.props;

		if (isLoading) {
			return (
				<LoadingContent/>
			)
		}
		if (!profile) {
			return (
				<Auth/>
			)
		}
    return (
	    <Cabinet/>
    )
	}
}

Router = withRouter(Router);

export default compose(
  connect(
    state => ({
			profile: state.profile.profile
    }),
    dispatch => ({
	    authGetMe: () => dispatch(authGetMe())
    }),
  ),
)(Router);
