const textParse = (str) => {
  str = (str || "").replace(/&/g, '&amp;');
  str = (str || "").replace(/</g, '&lt;');
  str = (str || "").replace(/>/g, '&gt;');
  str = (str || "").replace(/\n/g, '<br/>');
  return str
}
const textParseBack = (str) => {
  str = (str || "").replace(/<br\/>/g, '\n');
  return str
}

export {
  textParse,
  textParseBack
}
