import React from "react";
import {
	Box,

} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";

const RootBox = ({ children }) => {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			{ children }
		</Box>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		padding: "20px 24px",
		boxSizing: "border-box",
		maxHeight: "calc(100vh - 156px)",
		position: "relative",

		width: "100%",
		borderRadius: "16px",
		border: "0.5px solid rgba(0, 0, 0, 0.10)",
		boxShadow: "0px 1px 2.5px 0px rgba(0, 0, 0, 0.10)",

		backgroundImage: "radial-gradient(rgb(0 0 0 / 5%) 20%, transparent 20%), radial-gradient(#fafafa 20%, transparent 20%)",
		backgroundColor: "#ffffff",
		backgroundPosition: "0 0, 150px 150px",
		backgroundSize: "5px 5px",
	}
}));

export default RootBox
