import {
	FFmpeg
} from "@ffmpeg/ffmpeg"
import {
	fetchFile
} from "@ffmpeg/util";

const convertorWebmToMp3 = async (webmBlob) => {
	const ffmpeg = new FFmpeg();
	await ffmpeg.load();

	const inputName = 'input.webm';
	const outputName = 'output.mp3';

	await ffmpeg.writeFile(inputName, await fetchFile(webmBlob));
	await ffmpeg.exec(["-i", inputName, outputName]);
	const fileData = await ffmpeg.readFile(outputName);
	return new Blob([fileData.buffer], {
		type: 'audio/mp3',
	})
};
const convertorBase64ToMp3 = async (base64) => {};

let currentPlayAudio = null;
const playMp3FromBase64 = async (base64, eventEnded) => {
	await closeCurrentPlayAudio();

	const _audio = new Audio(['data:audio/mp3;base64,',base64].join(' '));
	if (eventEnded) {
		_audio.onended = eventEnded;
		_audio.onpause = eventEnded;
		_audio.oncancel = eventEnded;
	}
	await _audio.load();
	await _audio.play();

	currentPlayAudio = _audio;


};
const closeCurrentPlayAudio = () => {
	if (currentPlayAudio) {
		currentPlayAudio.pause();
	}
};

export {
	playMp3FromBase64,
	closeCurrentPlayAudio,

	convertorWebmToMp3,
	convertorBase64ToMp3
}
