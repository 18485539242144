import React, {Component} from "react";
import {
	Grid,
} from "@mui/material";
import {
	Header,
	Loading,
	RootBox,
	EmptyContent,
	ActionContent,
	MessagesContent
} from "./components";
import {
	ListWorkouts,
	VisibleContent,
	BackdropLoading,
	DialogConfirmation
} from "../../../components";
import {
	Notification,
	notificationTypes
} from "../../../common/Notification";
import {
	textParse
} from "../../../helpers/textParse";
import {
	closeCurrentPlayAudio,
	convertorWebmToMp3
} from "../../../helpers/voice";
import agent from "../../../agent/agent";

class Assistant extends Component {
	constructor(props) {
		super(props);

		this.state = {
			answers: [],

			assistant: null,
			assistantId: null,

			isActive: false,
			isLoading: true,
			isBackdrop: false,
			isLoadingAnswers: true,
		};
		this.refDialogConfirmation = React.createRef();
	}

	componentDidMount = async () => {
		await this.initState();
	}
	componentDidUpdate = async (prevProps) => {
		const prevId = prevProps.match.params.id;
		const currentId = this.props.match.params.id;
		if (prevId !== currentId) {
			closeCurrentPlayAudio();
			await this.initState();
		}
	}

	initState = async () => {
		await this.getTrainer();
	}

	getTrainer = async () => {
		await this.setState({ isLoading: true });
		const assistantId = this.props?.match?.params?.id;
		const assistant = await agent.get(`/api/v1/trainers/${ assistantId }`)
			.then((res) => res?.data || null)
			.catch(() => null)
		if (!assistant) {
			Notification({
				message: "Тренажёр не найден",
				type: notificationTypes.error
			})
			this.props.history.push("/")
			return
		}
		await this.setState({
			assistant,
			assistantId
		}, async () => {
			await this.getAnswers();
		});
	}

	getAnswers = async ({ isFirstPlaySound } = {}) => {
		await this.setState({ isLoadingAnswers: true });
		const { assistantId } = this.state;
		let res = await agent.get(`/api/v1/trainers/answers/${ assistantId }`)
			.then((res) => {
				let _items = [...(res?.data || [])].map((t) => {
						return {
							...t,
							user_message: textParse(t?.user_message),
							assistant_message: textParse(t?.assistant_message),
						}
					});
				return _items.reverse()
			})
			.catch(() => [])

		if (isFirstPlaySound && res?.[0]?.audio) {
			res[0]['isAutoplay'] = true;
		}

		await this.setState({
			answers: res,
			isLoading: false,
			isBackdrop: false,
			isLoadingAnswers: false,
		})
	}
	sendAnswerText = async (answer) => {
		await this.setState({ isBackdrop: true });
		const { assistant } = this.state;
		const formData = new FormData();
		formData.append("user_message", answer);
		const isSuccess = await agent.post(`/api/v1/trainers/request/${ assistant.assistant_id }`, formData)
			.then(() => true)
			.catch(() => false)
		if (!isSuccess) {
			await this.setState({ isBackdrop: false });
			Notification({
				message: "Сообщение не было отправлено",
				type: notificationTypes.error
			})
			return
		}
		await this.getAnswers();
	}
	sendAnswerVoice = async (sound) => {
		await this.setState({ isBackdrop: true });
		const { assistant } = this.state;

		const mp3 = await convertorWebmToMp3(sound);

		const formData = new FormData();
		formData.append("file", mp3, "sound.mp3");

		const isSuccess = await agent.post(`/api/v1/trainers/request/${ assistant.assistant_id }`, formData)
			.then(() => true)
			.catch(() => false)
		if (!isSuccess) {
			await this.setState({ isBackdrop: false });
			Notification({
				message: "Сообщение не было отправлено",
				type: notificationTypes.error
			})
			return
		}

		await this.getAnswers({ isFirstPlaySound: true });
	}

	stopTraining = async (isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.open({
				title: "Завершить тренировку",
				message: "Завершение тренировки сбрасывает все результаты текущий тренировки, подтверждаете действия?",
				acceptButtonTitle: "Да, завершить",
				cancelButtonTitle: "Нет",
				onSubmit: this.stopTraining.bind(this, true)
			})
			return
		}
		await this.setState({ isBackdrop: true });
		const { assistant } = this.state;
		const isSuccess = await agent.delete(`/api/v1/trainers/${ assistant.assistant_id }`)
			.then(() => true).catch(() => false);
		if (!isSuccess) {
			await this.setState({ isBackdrop: false });
			Notification({
				message: "Ошибка завершения тренировки, повторите попытку позднее.",
				type: notificationTypes.error
			})
			return
		}
		await this.getTrainer();
		await this.getAnswers();
		await this.setState({ isBackdrop: false });
		Notification({
			message: "Тренировка успешно завершена",
			type: notificationTypes.success
		})
	}
	startTraining = async () => {
		await this.setState({ isBackdrop: true });
		const { assistant } = this.state;
		const res = await agent.post(`/api/v1/trainers/create/${ assistant.assistant_id }`, {
			group_id: assistant?.group_id,
			thread_id: assistant?.thread_id,
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response?.data?.message || "Ошибка сервера"}
		});
		if (res?.error) {
			await this.setState({ isBackdrop: false });
			Notification({
				message: res?.error,
				type: notificationTypes.error
			})
			return
		}

		await this.getAnswers();
		await this.setState({
			isBackdrop: false
		});
	}

	render() {
		const {
			answers,
			assistant,
			isLoading,
			isBackdrop
		} = this.state;

		return (
			<>
        <Grid container spacing={2}>
	        <Grid item xs={3}>
		        <ListWorkouts/>
	        </Grid>
	        <Grid item xs={9} sx={{display: "flex", flexDirection: "column", minHeight: "calc(100vh - 92px)"}}>
		        <Header
			        title={isLoading ? 'Загружаем тренажер...' : assistant?.name}
			        isStop={Boolean(!isLoading && answers.length > 0)}
			        onStop={this.stopTraining.bind(this, false)}
		        />
		        <RootBox>
			        <VisibleContent visible={Boolean(isLoading)}>
				        <Loading/>
			        </VisibleContent>
			        <VisibleContent visible={Boolean(!isLoading && answers.length <= 0)}>
				        <EmptyContent
					        item={assistant}
					        onStart={this.startTraining}
				        />
			        </VisibleContent>
			        <VisibleContent visible={Boolean(!isLoading && answers.length > 0)}>
				        <MessagesContent list={answers}/>
				        <ActionContent
					        onSend={this.sendAnswerText}
					        onVoice={this.sendAnswerVoice}
				        />
			        </VisibleContent>
		        </RootBox>
	        </Grid>
        </Grid>

				<BackdropLoading open={isBackdrop}/>
				<DialogConfirmation ref={this.refDialogConfirmation}/>
			</>
		);
	}
}

export default Assistant
