import React from "react";
import {
	Box,
	Grid,
	Button,
	TextField,
	LinearProgress
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Send as SendIcon,
	KeyboardVoice as KeyboardVoiceIcon
} from "@mui/icons-material";
import {
	VisibleContent
} from "../../../../../components";
import {
	useAudioRecorder
} from 'react-audio-voice-recorder';
import {closeCurrentPlayAudio} from "../../../../../helpers/voice";

const ActionContent = ({onSend, onVoice}) => {
	const classes = useStyles();

	const {
		isRecording,
		startRecording,
		stopRecording,
		recordingBlob,
		mediaRecorder
	} = useAudioRecorder({
		noiseSuppression: true
	});
	React.useEffect(() => {
		if (!recordingBlob) return;
		(async () => {
			await onVoice(recordingBlob);
		})();
	}, [recordingBlob]);

	const [message, setMessage] = React.useState("");
	const handleSendMessage = () => {
		if (!message) {
			return
		}
		onSend(message);
		setMessage("")
	};
	const handleChangeMessage = ({ target }) => {
		setMessage(target?.value);
	};

	const eventKeyboard = async (event) => {
		if (event?.keyCode === 13 && isRecording) {
			await handleClickButton();
			return
		}
		if (event?.keyCode === 13 && event?.ctrlKey && !isRecording) {
			handleSendMessage();
			return
		}
	};
	React.useEffect(() => {
		window.addEventListener("keydown", eventKeyboard, false);
		return (() => {
			window.removeEventListener("keydown", eventKeyboard, false);
		});
	}, [message, isRecording]);

	const IconButtonSend = Boolean(message.length > 0 || isRecording) ? SendIcon : KeyboardVoiceIcon;
	const handleClickButton = async () => {
		if (isRecording) {
			await stopRecording();
			return
		}
		if (Boolean(message.length > 0)) {
			handleSendMessage()
		} else {
			closeCurrentPlayAudio();
			await startRecording();
		}
	};

	return (
		<Box className={classes.root}>
			<Grid container spacing={1} alignItems="center">
				<Grid item sx={{flex: "auto"}}>
					<VisibleContent visible={!isRecording}>
						<TextField
							value={message}
							minRows={1}
							maxRows={2}
							multiline
							fullWidth
							className={classes.input}
							placeholder="Написать сообщение..."
							onChange={handleChangeMessage}
						/>
					</VisibleContent>
					<VisibleContent visible={isRecording}>
						<LinearProgress/>
					</VisibleContent>
				</Grid>
				<Grid item>
					<Button
						variant="outlined"
						className={classes.button}
						onClick={handleClickButton}
					>
						<IconButtonSend sx={{fontSize: 24}}/>
					</Button>
				</Grid>
			</Grid>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		position: "absolute",
		left: "8px", right: "8px", bottom: "8px",
		background: "white",
		padding: "12px",
		borderRadius: "8px",
		boxShadow: "-1px 5px 12px 0px #0F448A0D,-3px 21px 22px 0px #0F448A0A,-7px 48px 29px 0px #0F448A08,-13px 85px 35px 0px #0F448A03,-20px 134px 38px 0px #0F448A00",
	},
	input: {
		"& .MuiInputBase-input": {
			borderRadius: "4px!important",
		}
	},
	button: {
		width: 36,
		height: 36,
		padding: "4px",
		boxSizing: "border-box",
		minWidth: "initial",
		borderRadius: "4px",
	}
}));

export default ActionContent
